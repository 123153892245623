import { render, staticRenderFns } from "./ConfigComplete.vue?vue&type=template&id=29cc8fc1&scoped=true&"
import script from "./ConfigComplete.vue?vue&type=script&lang=js&"
export * from "./ConfigComplete.vue?vue&type=script&lang=js&"
import style0 from "./ConfigComplete.vue?vue&type=style&index=0&id=29cc8fc1&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "29cc8fc1",
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CBox: require('@chakra-ui/vue').CBox, CHeading: require('@chakra-ui/vue').CHeading, CText: require('@chakra-ui/vue').CText, CInput: require('@chakra-ui/vue').CInput, CImage: require('@chakra-ui/vue').CImage, CStack: require('@chakra-ui/vue').CStack, CGrid: require('@chakra-ui/vue').CGrid, CDivider: require('@chakra-ui/vue').CDivider})
