<template>
  <!-- <CBox bg="bgMain" height="full">
    <CBox ml="60px" mr="60px" pt="20px" pl="10px" pr="10px" pb="5px"> -->
  <CBox my="20px">
      <!-- <CHeading color="fontMain" size="2xl">Dashboard Premium</CHeading>
      <CDivider borderColor="dividerColor" /> -->
      <CGrid templateColumns="7fr 3fr" :gap="6">
        <CBox w="100%" h="min-content" bg="white" border="1px" borderColor="borderBox">
          <!-- <CBox d="flex" mx="32px" my="15px">
            <BreadcrumbUpload />
          </CBox>
          <CDivider borderColor="borderBox" border="1.5px" /> -->
          <!-- <CBox mx="32px" my="20px"> -->
            <CBox v-if="!currentUser.emp_id">
              <CBox h="20px" />
              <Loading />
            </CBox>
            <CBox v-if="currentUser.emp_id" mx="32px" my="20px" maxH="calc(100vh - 270px)" overflowY="scroll">
              <CGrid templateColumns="7fr 3fr" :gap="6">
                <CBox mx="20px" my="20px">
                  <CHeading size="xl">Selamat!</CHeading>
                  <CText pt="10px">Perubahan berhasil disimpan.</CText>
                  <CBox mt="70px">
                    <CBox d="flex" v-if="!isEdit">
                      <CText fontWeight="normal" color="fontMain">{{ webcv_url_label }}/</CText>
                      <CText fontWeight="bold" color="fontMain">{{ currentUser.emp_username }}</CText>
                      <CBox ml="20px">
                        <ButtonSecondary @click.native="isEdit=true" label="Edit" size="xs" />
                      </CBox>
                    </CBox>
                    <CBox d="flex" v-if="isEdit">
                      <CText fontWeight="normal" color="fontMain" pt="7px">{{ webcv_url_label }}/</CText>
                      <CInput variant="flushed" v-model="form.username" />
                      <!-- <CText fontWeight="bold" color="fontMain">{{ currentUser.emp_username }}</CText> -->
                      <CBox ml="20px">
                        <ButtonSecondary @click.native="onSubmit()" label="Simpan" size="xs" :disabled="disabledButton" />
                      </CBox>
                    </CBox>
                    <CStack d="table-cell" direction="row" v-if="successMessage" verticalAlign="middle">
                      <CBox d="table-cell" v-if="successMessage" verticalAlign="middle">
                        <CImage :src="require('@/assets/icon/check.png')" w="15px" h="15px" />
                      </CBox>
                      <CBox d="table-cell" v-if="successMessage" verticalAlign="middle" pl="5px">
                        <CText color="green.500" fontSize="12px">{{ successMessage }}</CText>
                      </CBox>
                    </CStack>
                    <CText @click.native="openWebcv()" class="pointer" color="activeMenu" pt="10px">Lihat halaman WEB anda ></CText>
                    <CBox mt="50px">
                      <CText color="fontInformation">Bagikan URL</CText>
                      <ShareComponent :currentUser="currentUser" mt="10px" />
                    </CBox>
                  </CBox>
                </CBox>
                <CBox>
                  <CImage :src="require('@/assets/img/complete-image.png')" h="380px" />
                </CBox>
              </CGrid>
            </CBox>
            <CDivider v-if="currentUser.emp_id" borderColor="borderBox" border="1.5px" />
            <CBox v-if="currentUser.emp_id" align="right" m="20px">
              <ButtonBlue label="Kembali ke Dashboard" @click.native="$router.push({ name: 'Dashboard Web CV' })" />
            </CBox>
          <!-- </CBox> -->
        </CBox>
        <CBox>
          <CBox mx="10px" w="100%" bg="white" border="1px" borderColor="borderBox" style="position: relative;">
            <CBox class="coba" borderLeft="1px" borderBottom="1px" borderColor="borderBox" style="position: absolute; top: 20px;" h="10px" />
            <CBox my="20px" mx="15px">
              <CText fontWeight="bold" color="fontInformation">Complete</CText>
              <CText color="fontInformation" fontSize="14px">
                Selamat! Web CV Anda berhasil dikonfigurasi.
              </CText>
              <CImage :src="require('@/assets/img/complete-ilustration.png')" />
            </CBox>
          </CBox>
        </CBox>
      </CGrid>
      <!-- <WizardFooter @onSubmit="$router.push({ name: 'Dashboard Web CV' })" label="Kembali ke Dashboard" /> -->
    <!-- </CBox> -->
  </CBox>
</template>

<script>
import { GET_USER, UPDATE_USER_EMPLOYEE } from "@/store/auth.module"
import ButtonBlue from "@/components/button/ButtonBlue.vue"
import ButtonSecondary from "@/components/button/ButtonSecondary.vue"
// import BreadcrumbUpload from "@/components/breadcrumb/BreadcrumbUpload.vue"
import ShareComponent from "@/components/ShareComponent.vue"
import config from "@/ConfigProvider"
// import WizardFooter from "@/components/footer/WizardFooter.vue";

export default {
  metaInfo: {
    title: "Sukses",
    titleTemplate: "%s - Gethired.id"
  },
  components: {
    ButtonBlue,
    ButtonSecondary,
    // BreadcrumbUpload,
    ShareComponent,
    // WizardFooter
  },
  data() {
    return {
      webcv_url: config.value("WEBCV_URL"),
      webcv_url_label: config.value("WEBCV_URL_LABEL"),
      isEdit: false,
      form: {
        username: "",
      },
      disabledButton: false,
      successMessage: "",
      errorMessage: "",
    }
  },
  mounted() {
    this.getData()
  },
  computed: {
    currentUser() {
      return this.$store.getters.currentUser;
    }
  },
  methods: {
    async getData() {
      // setTimeout(async () => {
        await this.$store.dispatch(GET_USER)
        const user = this.$store.getters.currentUser;
        this.form.username = user.emp_username;
      // }, 100);
    },
    openWebcv() {
      window.open(this.webcv_url+'/'+this.currentUser.emp_username, '_blank');
    },
    onSubmit() {
      this.disabledButton = true;
      this.successMessage = ""
      this.errorMessage = ""

      this.$store
        .dispatch(UPDATE_USER_EMPLOYEE, {
          id: this.currentUser.emp_id,
          data: {
            emp_username: this.form.username
          }
        })
        .then(async () => {
          await this.$store.dispatch(GET_USER)
          this.disabledButton = false;
          this.successMessage = "URL profil berhasil digunakan"
          this.isEdit = false
        })
        .catch((err) => {
          this.disabledButton = false;
          let errData = err.message;
          if (err.response && err.response.data && err.response.data.message)
            errData = err.response.data.message;
          this.isEdit = false
          this.errorMessage = errData
        });
    },
  }
}
</script>

<style scoped>
.coba {
  content: "";
  left: calc(-0.5rem);
  transform: rotate(45deg);
  background-color: rgb(255, 255, 255);
  display: block;
  width: 1rem;
  height: 1rem;
}
.pointer {
  cursor: pointer;
}
</style>
